import React from "react"
import Container from "../components/Container"
import Seo from "../components/seo"
import "twin.macro"

const ContactSubmitted: React.FC = () => {
    return (
        <>
        <Seo title="Contact Form Submitted!" />
        <Container>
            <div tw="text-center">
            <h1 tw="mb-3 text-3xl font-display">Thank you for contacting me!</h1>
            <p>I will respond as soon as possible!</p>
            </div>
        </Container>
        </>
    )
}

export default ContactSubmitted